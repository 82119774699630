import React from 'react';
import { Descriptions, Tabs } from 'antd';
import Detail from './detail';
import RelationShip from './relationship';
import ColumnUsage from './columnusage';
import FilterUsage from './filterusage';
import AdHocQuery from './adhocquery';
import RSAAdsDropFormST1 from './query/rsa-ads-drop-stage1';
import RSAAdsDropFormST2 from './query/rsa-ads-drop-stage2';
import DebuggingToolFormST1 from './query/debugging-tool-stage1';
import SpendByOperation from './query/spend-by-operation';
import ConsumerTable from './consumertable';
import Sample from './sample';

const { TabPane } = Tabs;

class TabsPanel extends React.Component {
  callback(key) {
    console.log(key);
  }

  render() {
    var detail = this.props.detail;
    var relations = this.props.relations;
    var hasRelation = relations != null && relations.length > 0;
    var words = this.props.words;
    var hasWords = words != null && words.length > 0;
    var filters = this.props.filters;
    var hasFilters = filters != null && filters.length > 0;
    // var conditionalColumns = this.props.conditionalColumns;
    // var hasConditionalColumns = conditionalColumns != null && conditionalColumns.length > 0;
    // console.log("tabs.js.detail: " + this.props.detail)
    // console.log("tabs.js.relations: " + this.props.relations)
    // console.log("tabs.js.words: " + this.props.words)
    // console.log("tabs.js.filters: " + this.props.filters)
    // console.log("tabs.js.conditionalColumns: " + this.props.conditionalColumns)
    var isC2cView = detail.name.startsWith("C2CMasterView");
    var isUnifiedLayerView = detail.name.startsWith("UnifiedLayer");
    var isC2sView = detail.name.startsWith("C2SView");
    var isDataRegistry = detail.name.startsWith("DataRegistry");
    var isCustomizedC2SReport = detail.name.startsWith("Data Analysis");
    var isRSAAdsDrop = detail.name.startsWith("RSAAdsDrop");
    var isDebuggingTool = detail.name.startsWith("AdsDropDebugging");
    var isSpendByOperation = detail.name.startsWith("SpendByOperation");
    // var hasSampleTab = detail.name.startsWith("DataRegistry(") || detail.name.startsWith("C2SView(");
    var hasSampleTab = false;

    if (isCustomizedC2SReport) {
      return (
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="Data Analysis Tools" key="1">
            <div ><br />
              {/* <p>This section contains a set of pre-defined tools for common demand data analysis needs.</p> */}
              <Descriptions title="This section contains a set of pre-defined tools for common demand data analysis needs."></Descriptions>
            </div>
          </TabPane>
        </Tabs>
      )
    } else if (isRSAAdsDrop) {
      return (
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="Top Contributors" key="1">
            <RSAAdsDropFormST1 accessToken={this.props.accessToken} assetName="RSAAdsDrop" userName={this.props.userName} submitstatus={""}>
            </RSAAdsDropFormST1>
          </TabPane>
          <TabPane tab="Daily Servable RSA Cnt Trend" key="2">
            <RSAAdsDropFormST2 accessToken={this.props.accessToken} assetName="RSAAdsDrop" userName={this.props.userName} submitstatus={""}>
            </RSAAdsDropFormST2>
          </TabPane>
        </Tabs>
      )
    } else if (isDebuggingTool) {
      return (
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="Status Drop Top Contributor" key="1">
            <DebuggingToolFormST1 accessToken={this.props.accessToken} assetName="AdsDropDebugging" userName={this.props.userName} submitstatus={""}>
            </DebuggingToolFormST1>
          </TabPane>
        </Tabs>
      )
    } else if (isSpendByOperation) {
      return (
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="Spend By Operation" key="1">
            <SpendByOperation accessToken={this.props.accessToken} assetName="SpendByOperation" userName={this.props.userName} submitstatus={""}>
            </SpendByOperation>
          </TabPane>
        </Tabs>
      )
    } else {
      return (
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          {
            <TabPane tab="Overview" key="1">
              <Detail detail={detail} accessToken={this.props.accessToken} searchValue={this.props.searchValue} userName={this.props.userName} />
            </TabPane>
          }
          {
            hasRelation ?
              <TabPane tab="Relationship" key="2">
                <RelationShip words={words}
                  relations={relations} assetName={detail.name} />
              </TabPane>
              : null
          }
          {
            hasWords ?
              <TabPane tab="Select" key="3">
                <ColumnUsage words={words} assetName={detail.name} />
              </TabPane>
              : null
          }
          {
            hasFilters ?
              <TabPane tab="Filter" key="4">
                <FilterUsage filters={filters} assetName={detail.name} />
              </TabPane>
              : null
          }
          {
            <TabPane tab="Consumer" key="5">
              <ConsumerTable
                accessToken={this.props.accessToken}
                assetName={detail.name}
              />
            </TabPane>
          }
          {
            // isC2cView ||
            (isC2sView || isDataRegistry) && detail.name != "C2SView(XandrSegment)"
              // || isUnifiedLayerView
              ?
              <TabPane tab="Query" key="6">
                <AdHocQuery
                  accessToken={this.props.accessToken}
                  userName={this.props.userName}
                  assetName={detail.name}
                  assetNames={detail.childrenNames}
                />
              </TabPane>
              : null
          }
          {
            isC2cView ?
              <TabPane tab="Guide" key="7">
                <div>
                  <p><span>C2C View Development Guide</span><br></br>
                    <a href="https://microsoft.sharepoint.com/teams/demandreference/SitePages/C2C%20View%20development%20guide.aspx">https://microsoft.sharepoint.com/teams/demandreference/SitePages/C2C%20View%20development%20guide.aspx</a></p>
                </div>
              </TabPane>
              : null
          }
          {
            isUnifiedLayerView ?
              <TabPane tab="Guide" key="8">
                <div>
                  <p><span>UnifiedLayer Guide</span><br></br>
                    <a href="https://msasg.visualstudio.com/Bing_Ads/_wiki/wikis/Bing_Ads.wiki/1451/UnifiedLayer">https://msasg.visualstudio.com/Bing_Ads/_wiki/wikis/Bing_Ads.wiki/1451/UnifiedLayer</a></p>
                </div>
              </TabPane>
              : null
          }
          {
            hasSampleTab ?
              <TabPane tab="Sample" key="9">
                <Sample assetName={detail.name} accessToken={this.props.accessToken} />
              </TabPane>
              : null
          }
        </Tabs>
      )
    }

  }
}
export default TabsPanel;